import React from "react";
import SelectLang from "../../hooks/selectLang";
import Portfolio from '../portfolio/Portfolio'

const Projects = () => {
  const language = SelectLang()
  return (
    <section id="portfolio" className="section theme-light dark-bg">
      <div className="container">
        {/* End separated */}
        <div className="title section">
          <h3>{language['portfolio_header']}</h3>
        </div>
        <Portfolio />
        {/* End .row */}
      </div>

      <div
          className="separated"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/border-dark.png"
            })`,
          }}
        ></div>
        
    </section>
  );
};

export default Projects;
