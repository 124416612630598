import React, { useEffect, useState } from "react";
import AOS from "aos";
import theme from "./theme/theme";
import { useDispatch, useSelector } from "react-redux";
import "aos/dist/aos.css";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import Settings from "./components/settings/Settings";
import { ThemeProvider } from "@material-ui/core";
import { selectColorList, setThemeColor } from "./redux/portfolioSlice";

const App = () => {
  const [appTheme, setAppTheme] = useState(theme)
  const dispatch = useDispatch()
  const colorsList = useSelector(selectColorList)
  
  useEffect(() => {
    AOS.init();
    const randomInt = Math.floor(Math.random() * colorsList.length);
    setAppTheme(theme)
    dispatch(setThemeColor(colorsList[randomInt]))
  },[]);

  return (
    <>
      <ThemeProvider theme={appTheme}>
        <ScrollToTop />
        <Settings />
        <Routes />
      </ThemeProvider>
    </>
  );
};

export default App;
