import React from "react";
import { useSelector } from "react-redux";
import SelectLang from "../../hooks/selectLang";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ComputerIcon from "@mui/icons-material/Computer";
import CreateIcon from "@mui/icons-material/Create";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { selectTheme } from "../../redux/portfolioSlice";

export default function Service() {
  const language = SelectLang();
  const isDark = useSelector(selectTheme);
  const lang = language["services"];
  const icons = {
    gear: <EngineeringIcon className="icon" />,
    computer: <ComputerIcon className="icon" />,
    "ui/ux": <CreateIcon className="icon" />,
    data: <TrendingUpIcon className="icon" />,
  };
  return (
    <div className="row">
      {lang.map((val, i) => (
        <div
          className="col-md-6 col-lg-4 my-3"
          key={i}
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay={val.delayAnimation}
        >
          <div
            className="feature-box-01"
            style={{ background: isDark ? "#1b1b1b" : "#e2e2e2" }}
          >
            {icons[val.icon]}
            <div className="feature-content">
              <h5>{val.title}</h5>
              <p>{val.descriptions}</p>
            </div>
          </div>
          {/* End .feature-box-01 */}
        </div>
      ))}
    </div>
  );
}
