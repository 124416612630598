import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";

import CancelIcon from "@mui/icons-material/Cancel";
import SettingsIcon from "@mui/icons-material/Settings";

import { useDispatch, useSelector } from "react-redux";
import {
  selectSettingsDrawerOpened,
  handleSettingsDrawer,
} from "../../redux/portfolioSlice";
import ColorPickerSettingsTab from "../../colorPicker/ColorPickerSettingsTab";
import { Divider } from "@material-ui/core";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import LanguageSelectToggle from "./LanguageSelectToggle";
import DarkModeSelectToggle from "./DarkModeSelectToggle";

export default function Settings() {
  const dispatch = useDispatch();
  const settingsDrawer = useSelector(selectSettingsDrawerOpened);
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
        dispatch(handleSettingsDrawer(false));
      }
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const closeDrawer = () => {
    dispatch(handleSettingsDrawer(false));
  };

  const openDrawer = () => {
    dispatch(handleSettingsDrawer(true));
  };

  return (
    <>
      {isVisible && (
        <div
          className="setting-toggler"
          data-aos="fade-left"
          data-aos-duration="800"
        >
          <IconButton style={{ margin: 0 }} onClick={() => openDrawer()}>
            <SettingsIcon style={{ fontSize: 28, color: "#6d6d6d" }} />
          </IconButton>
        </div>
      )}

      <div>
        <div className="hb-top-fixed d-flex">
          <div className="hb-info">
            <Grid
              container
              direction="column"
              style={{
                position: "fixed",
                alignItems: "center",
                backgroundColor: "#b9b9b9",
                padding: "2rem 0",
                top: "1.4rem",
                right: 0,
                transition: "all 0.5s ease",
                borderRadius: "30px 0 0 30px",
                width: settingsDrawer ? "12rem" : "0rem",
                height: 250,
                zIndex: 9900,
              }}
            >
              <div
                style={{
                  position: "fixed",
                  alignItems: "center",
                  top: "1.25rem",
                  right: 0,
                  transition: "all 0.5s ease",
                  borderRadius: "30px 0 0 30px",
                  width: settingsDrawer ? "11.5rem" : "0rem",
                  zIndex: 9900,
                }}
              >
                <IconButton onClick={() => closeDrawer()}>
                  <CancelIcon />
                </IconButton>
              </div>
              <List>
              <ListItem>
                  <DarkModeSelectToggle />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <LanguageSelectToggle />
                </ListItem>
                <Divider  component="li" />
                <ListItem>
                  <ColorPickerSettingsTab isSettingTab={true} />
                </ListItem>
              </List>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
