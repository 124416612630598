import * as React from "react";
import {
  selectColorList,
  selectThemeColor,
  setThemeColor,
  selectSettingsDrawerOpened
} from "../redux/portfolioSlice";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { TwitterPicker } from "react-color";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function ColorPicker({ isSettingTab = false }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const themeColor = useSelector(selectThemeColor);
  const colorsList = useSelector(selectColorList);
  const settingsDrawer = useSelector(selectSettingsDrawerOpened);

  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const toggleColorPicker = () => setShowColorPicker(!showColorPicker);

  React.useEffect(() => {
    const toggleVisibility = () => setShowColorPicker(false);
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  React.useEffect(() => {
    if (!settingsDrawer){
      setShowColorPicker(false)
    }
  },[settingsDrawer])

  const handleChangeComplete = (color, event) => {
    const selectedColor = color.hex;
    dispatch(setThemeColor(selectedColor));
  };

  function changeBackgroundOnOver(e) {
    e.target.style.background = "#dddddd";
  }

  function changeBackgroundOnOut(e) {
    e.target.style.background = "#ebebeb";
  }

  return (
    <>
      <div className={classes.swatch} onClick={() => toggleColorPicker()}>
        <div
          className={classes.color}
          style={{ background: themeColor["--themeColor"] }}
        />
        <ArrowDropDownIcon
          className={classes.arrowDropDownIcon}
          onMouseOver={changeBackgroundOnOver}
          onMouseOut={changeBackgroundOnOut}
        />
      </div>
      <div className={classes.colorPicker}>
        {showColorPicker ? (
          <TwitterPicker
            className={isSettingTab ? classes.colorPickerSettings : classes.colorPicker}
            triangle={"top-right"}
            colors={colorsList}
            color={themeColor["--themeColor"]}
            onChangeComplete={handleChangeComplete}
          />
        ) : null}
      </div>
    </>
  );
}

const useStyles = makeStyles({
  color: {
    width: "36px",
    borderRadius: "2px",
  },
  swatch: {
    display: "flex",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    cursor: "pointer",
  },
  colorPicker: {
    position: "fixed",
    right: "1.3rem",
    marginTop: 6,
  },
  colorPickerSettings : {
    position: "fixed",
    right: "2.1rem",
    marginTop: 6,
  },
  arrowDropDownIcon: {
    flex: 1,
    background: "#ebebeb",
  },
});
