import * as React from "react";
import { makeStyles, List, ListItem, ListItemText } from "@material-ui/core";
import {
  selectLanguage,
  changeLanguage,
  selectThemeColor,
} from "../../redux/portfolioSlice";
import { useDispatch, useSelector } from "react-redux";

export default function LanguageSelectToggle() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isEnglish = useSelector(selectLanguage);
  const themeColor = useSelector(selectThemeColor);
  
  const toggleLang = () => {
    dispatch(changeLanguage());
  };

  return (
    <>
      <div className={classes.langContainer} style={themeColor}>
        <List className={classes.nav}>
          <ListItem
            className={classes.listItem}
            style={{ background: !isEnglish ? "" : themeColor["--themeColor"] }}
            onClick={() => toggleLang()}
          >
            <ListItemText className={classes.toggleText}>EN</ListItemText>
          </ListItem>

          <ListItem
            className={classes.listItem}
            style={{ background: isEnglish ? "" : themeColor["--themeColor"] }}
            onClick={() => toggleLang()}
          >
            <ListItemText className={classes.toggleText}>JP</ListItemText>
          </ListItem>
        </List>
      </div>
    </>
  );
}

const useStyles = makeStyles({
  langContainer: {
    // marginLeft: "auto",
  },
  nav: {
    display: "flex",
    paddingTop: 0,
    cursor: "pointer",
    paddingLeft: 16,
  },
  listItem: {
    color: "#ffffff",
    height: 24,
    width: 50,
  },
  toggleText: {
    fontSize: 10,
    color: "#ffffff",
    fontWeight: 400,
  },
});
