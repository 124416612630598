import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import Particles from "react-tsparticles";

import SelectLang from "../../hooks/selectLang";
import ColorPicker from "../../colorPicker/ColorPicker";
import { selectThemeColor } from "../../redux/portfolioSlice";
import DarkModeSelectToggle from "../settings/DarkModeSelectToggle";
import LanguageSelectToggle from "../settings/LanguageSelectToggle";
import { TextLoop } from "react-text-loop-next";

const Slider = () => {
  const language = SelectLang();
  const lang = language["slider"];
  const themeColor = useSelector(selectThemeColor);

  return (
    <>
      {/*  Home Banner */}
      <section
        id="home"
        className="home-banner home-banner-two portfolio-slider "
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "assets/img/me.jpg"
          })`,
        }}
      >
        <Grid
          className="hb-top-fixed d-flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {/* <Grid item>
            <Grid container className="hb-info">
              <Grid item style={{ marginRight: "1.2rem" }}>
                <a href="tel:+01 201-398-8872">{contactInfo.phone}</a>
              </Grid>
              <Grid item style={{ marginLeft: "1.2rem" }}>
                <a href="mailto:ushiolshinohara@gmail.com">
                  {contactInfo.email}
                </a>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item> </Grid>
          <Grid item>
            <Grid container justifyContent="space-evenly">
              <Grid item>
                <LanguageSelectToggle />
              </Grid>
              <Grid item style={{ marginLeft: "1.2rem" }}>
                <DarkModeSelectToggle />
              </Grid>
              <Grid item style={{ marginLeft: "1.2rem" }}>
                <ColorPicker />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className="frame-layout__particles">
          <Particles
            options={{
              fpsLimit: 120,
              fullScreen: { enable: false },
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  bubble: {
                    distance: 400,
                    duration: 2,
                    opacity: 0.8,
                    size: 60,
                  },
                  push: {
                    quantity: 8,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: "#ffffff",
                },
                links: {
                  color: "#ffffff",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1.5,
                },
                collisions: {
                  enable: true,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outMode: "bounce",
                  random: false,
                  speed: 2,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 800,
                  },
                  value: 80,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  random: true,
                  value: 5,
                },
              },
              detectRetina: true,
            }}
          />
        </div>

        <div className="container" style={themeColor}>
          <div
            className="row full-screen align-items-center"
            style={themeColor}
          >
            <div className="col-lg-12" style={themeColor}>
              <div className="type-box" style={themeColor}>
                <h6 data-aos="fade-up" data-aos-duration="1200">
                  {lang.header}
                </h6>
                <h1
                  className="font-alt"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  {lang.name}
                </h1>

                <div
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="200"
                  style={themeColor}
                >
                  <TextLoop>
                    <p className="loop-text lead">Software Engineer</p>
                    <p className="loop-text lead">
                      Web/Mobile Application Developer
                    </p>
                    <p className="loop-text lead">UI/UX Designer</p>
                  </TextLoop>
                </div>

                <p
                  className="desc"
                  style={{ color: "#cdcecf" }}
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                >
                  {lang.description}
                </p>
                <div className="d-flex btn-wrapper">
                  <a
                    className="px-btn px-btn-theme mr-4"
                    href="assets/resume/Resume.pdf"
                    target="_blank"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay="400"
                  >
                    {lang.btnText}
                  </a>
                  <a
                    className="px-btn btn-outline"
                    href="#portfolio"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay="500"
                  >
                    {lang.btnText2}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Container*/}
        <div className="go-to go-to-next" style={themeColor}>
          <a href="#about">
            <span></span>
          </a>
        </div>
      </section>

      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
