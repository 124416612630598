import React from "react";

import Header from "../components/header/Header";
import Slider from "../components/slider/Slider";
import About from "../components/about/About";
import Resume from "../components/resume/Resume";
import Projects from "../components/projects/Projects";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { useSelector } from "react-redux";
import { selectTheme, selectThemeColor } from "../redux/portfolioSlice";

const Home = () => {
  useDocumentTitle("Louis Shinohara");

  const theme = useSelector(selectTheme);
  const themeColor = useSelector(selectThemeColor);

  React.useEffect(() => {
    const isDark = true;
    const currentTheme = document.body.classList;
    if (theme === isDark) {
      currentTheme.remove("theme-light");
      currentTheme.add("theme-dark");
    } else {
      currentTheme.remove("theme-dark");
      currentTheme.add("theme-light");
    }
  }, [theme]);

  return (
    <div className="main-left" style={themeColor}>
      <Header />
      <Slider />
      <About />
      <Resume />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
