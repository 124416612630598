import React from "react";
import SelectLang from "../../hooks/selectLang";
// import Skills from "../skills/Skills";
import { useSelector } from "react-redux";
import { selectTheme, selectThemeColor } from "../../redux/portfolioSlice";

const Resume = () => {
  const language = SelectLang();
  const themeColor = useSelector(selectThemeColor);
  const isDark = useSelector(selectTheme);
  const educationHeader = language["education_header"];
  const educatonContent = language["education"];
  const experienceHeader = language["experience_header"];
  const experience = language["resume"];
  return (
    <>
      <section id="resume" className="section-about dark-bg" style={themeColor}>
        <div className="container">
          {/* <div className="title">
            <h3>{educationHeader}</h3>{" "}
          </div>

          <div className="row align-items-center">
            <div
              className="col-lg-4 m-15px-tb"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <ul className="aducation-box">
                {educatonContent.map((val, i) => (
                  <li key={i}>
                    <h6>{val.degreeTitle} </h6>
                    <p>{val.instituteName}</p> <span>{val.passingYear}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div
              className="col-lg-7 ml-auto m-15px-tb"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
            </div>
          </div> */}
          <div className="title">
            <h3>{experienceHeader}</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
            {experience.map((val, i) => (
              <div
                className="resume-row"
                key={i}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay={val.delayAnimation}
                style={{ backgroundColor: isDark ? '#1b1b1b' : '#e2e2e2'}}
              >
                <div className="row" key={i}>
                  <div className="col-md-4 col-xl-3">
                    <div className="rb-left">
                      <h4>{val.companyName}</h4>
                      <p>{val.jobDuration}</p>
                      {val.skillsUsed.map((skill) => {
                        return (
                          <div className="rb-time" style={{ margin: 2 }}>
                            {skill}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-8 col-xl-9">
                    <div className="rb-right">
                      <h5>{val.jobPosition}</h5>
                      <p>{val.jobDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* separated */}
        </div>
      </section>
    </>
  );
};

export default Resume;
