import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";
import {
  FiUser,
  FiBriefcase,
  FiFileText,
  FiPhoneOutgoing,
} from "react-icons/fi";
import { FaHome } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { selectTheme, selectThemeColor } from "../../redux/portfolioSlice";
import Settings from "../settings/Settings";

// For mobile, I have to rewrite the code for some reason and the
// modal body has to be on top of all the code
// Also so I made it so the settings only appears when you scroll up and
// disappears when it goes down

const Header = () => {
  const [click, setClick] = useState(false);
  const themeColor = useSelector(selectThemeColor);
  const isDark = useSelector(selectTheme);
  const handleClick = () => setClick(!click);

  return (
    <>
      {/* Header */}
      <div className="mob-header">
        <Settings />
        <button
          className="toggler-menu"
          onClick={handleClick}
          style={{
            background: themeColor["--themeColor"],
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <div className={click ? "active" : ""}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      {/* End Header */}

      {/* nav bar */}
      <header
        className={
          click
            ? "header-left header-menu-style-two menu-open"
            : "header-left header-menu-style-two"
        }
      >
        <div
          className="scroll-bar"
          style={{ backgroundColor: isDark ? "#1b1b1b" : "#f8f8f8" }}
        >
          <div className="hl-top">
            <div className="hl-logo">
              <Link href="home#home" to="/">LS</Link>
            </div>
          </div>
          {/* End htl-top */}

          <Scrollspy
            className="nav nav-menu"
            items={["home", "about", "resume", "portfolio", "contactus"]}
            currentClassName="active"
            offset={-30}
          >
            <li>
              <a className="nav-link " href="#home" onClick={handleClick}>
                <FaHome />
                <span className="item">Home</span>
              </a>
            </li>
            <li>
              <a className="nav-link" href="#about" onClick={handleClick}>
                <FiUser />
                <span className="item">About</span>
              </a>
            </li>
            <li>
              <a className="nav-link" href="#resume" onClick={handleClick}>
                <FiFileText />
                <span className="item">Resume</span>
              </a>
            </li>
            <li>
              <a className="nav-link" href="#portfolio" onClick={handleClick}>
                <FiBriefcase />
                <span className="item">Portfolio</span>
              </a>
            </li>
            <li>
              <a className="nav-link" href="#contactus" onClick={handleClick}>
                <FiPhoneOutgoing />
                <span className="item">Contact</span>
              </a>
            </li>
          </Scrollspy>
        </div>
      </header>
      {/* End Header */}
    </>
  );
};

export default Header;
