import React from "react";
import { useSelector } from "react-redux";
import { selectTheme } from "../../redux/portfolioSlice";
import { SocialShare } from "../Social";
const Footer = () => {
  const isDark = useSelector(selectTheme)
  return (
    <>
      <footer
        className="footer white"
        style={{ backgroundColor: isDark ? "#1b1b1b" : "#f8f8f8", marginTop: 30 }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 my-2">
              <div className="nav justify-content-center justify-content-md-start">
                {SocialShare.map((val, i) => (
                  <a
                    key={i}
                    href={`${val.link}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {val.Social}
                  </a>
                ))}
              </div>
              {/* End .nav */}
            </div>
            {/* End .col */}

            <div className="col-md-6 my-2 text-center text-md-end">
              <p>Last Updated: February 2022</p>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </div>
      </footer>
    </>
  );
};

export default Footer;
