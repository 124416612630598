import React from "react";
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";
import Map from "./Map";

const Contact = () => {
  return (
    <section id="contactus" className="section theme-light dark-bg-contact" style={{padding: 0}}>
      <div className="container">
        <div className="row">
          <div className="col-12" style={{paddingBottom: 20}}>
            <Map />
            {/* End google-map */}
          </div>
          <div
            className="col-lg-5 col-xl-4 m-15px-tb"
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            <ContactInfo />
          </div>
          {/* End Contact info */}

          <div
            className="col-lg-7 ml-auto m-15px-tb"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="200"
          >
            <div className="contact-form">
              <ContactForm />
            </div>
          </div>
          {/* End contact form */}
        </div>
      </div>
    </section>
  );
};

export default Contact;
