import { createTheme } from '@material-ui/core'

export const customColor = {
    blue: '#2F6FF3',
    white: '#fff !default',
    black: '#000 !default'
}

const theme = createTheme({
    palette: {
        base: {
            themeColor: customColor.blue,
            whiteBackground: customColor.white,
            blackBackground: customColor.black,
        },
    }})

export default theme