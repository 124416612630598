import { en, jp } from '../text'
import { selectLanguage } from '../redux/portfolioSlice';
import { useSelector } from "react-redux";

const SelectLang = () => {
  const lang = useSelector(selectLanguage);
  const EN = true;
  if (lang === EN) {
    return en
  } else {
    return jp
  }
}

export default SelectLang
