import { makeStyles } from '@material-ui/core/styles'

const ContactFormStyles = makeStyles((theme) => ({
  alertShown: {
    opacity: 1,
    transition: 'all 250ms linear',
  },
  alertHidden: {
    opacity: 0,
    transition: 'all 250ms linear 2s'
  }
}))

export default ContactFormStyles