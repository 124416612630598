export const en = {
  lang: "en",
  about: {
    Language: "en",
    Name: "Louis Shinohara",
    Title: "Software Engineer",
    description1: `Hello and welcome to my website! My name's Louis Shinohara and I graduated from Rutgers University in NJ! I began my journey as a Software Engineer in college after being inspired by watching the TV series Mr.Robot. In my free time, I enjoy working on side projects, playing Super Smash Brothers, working out and BMX 🚲 `,
    Education: "Rutgers, The State University of NJ",
    Birthday: "October 11th 1996",
    Address: "Jersey City, NJ",
    Phone: "+1 (201)-398-8872",
    Email: "ushiolshinohara@gmail.com",
    Major: "B.S Electrical/Computer Engineering & Computer Science",
    Affiliations: "Theta Tau Omicron Delta Chapter, Tau Beta Pi Beta Chapter",
  },
  about_const: {
    Language: "en",
    Biography: "About Me",
    Major: "Major: ",
    Education: "Education: ",
    Birthday: "Birthday: ",
    Address: "Address: ",
    Phone: "Phone: ",
    Email: "Email: ",
    WhatIDo: "What Do I Do?",
    Affiliations: "Affiliations: ",
    Education_Header: "Education",
  },
  contact: {
    Header: "Let's get in touch!",
    Info: "I'm always available to speak about any opportunities. Please feel free to reach out to me and I'll get back to you as soon as I can!",
  },
  contactForm: {
    NameHeader: "Full Name",
    NameRequired: "Name Is Required",
    EmailHeader: "Email Address",
    EmailRequired: "Email Is Required",
    EmailFormatError: "Entered Value Does Not Match Email Format",
    SubjectHeader: "Subject",
    SubjectRequired: "Subject Is Required",
    MessageHeader: "Leave Your Message Here",
    MessageRequired: "Message Is Required",
    SendMessage: "Send Message",
  },
  education_header: "Education",
  education: [
    {
      passingYear: "2018-2021",
      degreeTitle:
        "B.S. Electrical and Computer Engineering & Computer Science",
      instituteName: "Rutgers, The State University",
    },
    {
      passingYear: "2011-2015",
      degreeTitle: "High School Diploma",
      instituteName: "Paramus High School",
    },
    {
      passingYear: "2003-2014",
      degreeTitle: "Diploma",
      instituteName: "Japanese Weekend School of NJ",
    },
  ],
  experience_header: "Professional Experience",
  fourofour: {
    PageNotFound: "404 - Page Not Found..",
    Message: "Whoops, it looks like the page you request wasn't found.",
    BackToHome: "BACK TO HOME",
  },
  resume: [
    {
      companyName: "Microsoft",
      jobPosition: `Software Engineer 2`,
      jobDuration: `July 2022 - Present`,
      skillsUsed: [
        "React",
        "Python",
        "Java",
        "Docker",
        "Kubernetes",
        "Big Data",
        "Map Reduce",
        "Spark",
      ],
      jobDescription: `As a Big Data Full Stack Engineer in AdTech, I specialize in managing a self-service big data platform. This platform supports data engineers and scientists by enabling them to submit a variety of jobs (Java, Python, MapReduce, Spark, Bash, etc.) through a user interface. These jobs are scheduled and executed by a backend scheduler application, all operating on a Hadoop infrastructure. Our system includes an alerting mechanism to monitor job schedules, ensuring timely execution. I'm also responsible for managing around 4,000 jobs that run on an hourly or daily basis.

      Additionally, my role involves working with SQL databases and various tools such as Docker, Kubernetes, and Jenkins to set up CI/CD pipelines. I frequently use data processing tools like Hive and Presto.
      
      Another key responsibility is managing a product called LLD, which delivers event-level ads data to over 800 global customers. This role requires me to work extensively with MapReduce and Spark jobs for data engineering tasks, including the creation and optimization of new jobs. My full-stack responsibilities provide me with extensive exposure to big data tools and techniques.
      
      Moreover, my work involves collaborating with cloud providers such as Azure, AWS, and Google Cloud Storage, further broadening my expertise in cloud-based big data solutions.`,
      delayAnimation: "",
    },
    {
      companyName: "Rutgers University",
      jobPosition: `Web Application Developer`,
      jobDuration: `September 2020 - Present`,
      skillsUsed: [`React`, "Redux", "Firebase"],
      jobDescription: `I am currently working on redesigning and implementing the University Portal to be used by over 80,000 students 
            faculty and staff across all 3 Rutgers campuses. After completing my internship at Optum-United Health Group,
            I was selected from the mobile app team to take our mobile designs and implement a responsive web application in React froms scratch. Our team has grown so much over the last
            few years and have faced a lot of challenges but am proud to say that the  beta version for the web application has been released 
            and we are months away from deploying the app publicly to the University!`,
      delayAnimation: "100",
    },
    {
      companyName: "AT&T",
      jobPosition: `Software Engineer`,
      jobDuration: `July 2021 - July 2022`,
      skillsUsed: [
        `React`,
        "Python",
        "Flask",
        "MySQL",
        "REST",
        "Docker",
        "Kubernetes",
      ],
      jobDescription: `For my first rotation as a part of the TDP program, I am a part of Xandr, the ad tech sector of the company.
            I have been exposed to many languages and technologies such as Scala, HDFS, Airflow, Spinnaker, data pipeling and much more. 
            For my first major project, I created a slack bot in Python to quickly gather information for a user from the company's database and deployed the application onto Kubernetes as a docker image.
            I am currently converting a flask app into a React app by converting the old application in Flask into a microservice that the React App will communicate with to retrieve it's data.
            I am also doing some work related to migrating on premisis applications into the cloud using Kubernetes.`,
      delayAnimation: "",
    },

    {
      companyName: "Rutgers University",
      jobPosition: `Mobile App Developer`,
      jobDuration: `December 2019 - September 2020`,
      skillsUsed: [`Flutter`, "Firebase", "AdobeXD"],
      jobDescription: `As a mobile app developer, I worked on the new redesigned version of the Official Rutgers Mobile App which will be used by all 3 Rutgers campuses. We started off as a 
            small team of 3 and initiated the repository for the new app from scratch. The size of our team has grown tremendously and we our currently in 
            the beta phase, months away from releasing our app to the University! Although I don't do direct work on the mobile app anymore, I still collaborate with the 
            team to ensure that the mobile app's design parallel's the web application.`,
      delayAnimation: "100",
    },
    {
      companyName: "Knowt",
      jobPosition: `Mobile App Developer`,
      jobDuration: `Jan 2020 - Apr 2021`,
      skillsUsed: [`React Native`],
      jobDescription: `Knowt is a start up company created by students and is a web and mobile app that utilizes 
            machine learning to generate various types of quizzes from user’s notes! My role as a mobile developer was to add new features
            and suggest any UI changes which I thought would improve the experience. It was a cool experience to work for a company of students and 
            to see my work directly influence the users.`,
      delayAnimation: "200",
    },
    {
      companyName: "Optum - UnitedHealth Group",
      jobPosition: `Full Stack Software Engineer`,
      jobDuration: `Jan 2020 - Aug 2020`,
      skillsUsed: [`React`, "Redux"],
      jobDescription: `During my time as a virtual intern at Optum, I built an employee predictive analytics web app tool 
            under internal corporate systems in React. The tool was used by top level executives to anticipate which employees are most 
            statistically likely to succeed if promoted to a higher position based on data collected from each employee. This internship was where I learned a lot about
            React which is now one of my strongest skills sets!`,
      delayAnimation: "300",
    },
  ],
  services: [
    {
      icon: "gear",
      title: "Software Engineering",
      descriptions: `I have designed full stack application architectures, relational/non-relational databases and REST API's which have been deployed to web servers and Kubernetes as Docker containers.`,
      delayAnimation: "",
    },
    {
      icon: "data",
      title: `Big Data`,
      descriptions: `I specialize in Big Data for our Advertisement Technology Self Service Data Platform. I am very familiar with a range of Big Data tools such as Hadoop, Hive, Presto, Trino, Spark & Map Reduce. \n \n`,
      delayAnimation: "200",
    },
    {
      icon: "computer",
      title: "Web/Mobile Application Development",
      descriptions: `I specialize in full stack development and have created various applications ranging from websites to internal 
            corporate tools and university wide applications.`,
      delayAnimation: "400",
    },
  ],
  slider: {
    header: "Hello my name is, ",
    name: "Louis Shinohara",
    description: `I'm a software engineer at Microsoft, specializing in Big Data for our Advertisement Technology Data Platform!`,
    btnText: "Download Resume",
    btnText2: "My Work",
  },
  tab_list: [
    "All",
    "UI/UX",
    "Web Dev",
    "Mobile Dev",
    "Database",
    "Artificial Intelligence",
    "Other",
  ],
  portfolio_header: "Portfolio.",
  portfolio_content: [
    {
      tag: ["UI/UX", "Web Dev"],
      img: "assets/portfolio-masonry/OT-site.gif",
      title: "thetatau.rutgers.edu",
      description: `Rutgers' Theta Tau's website was the first major project that I worked on. After becoming a new member of the fraternity, I was elected as web master and took 
                it upon myself to create a new site with a fresh design from scratch without a template over the summer. My biggest challenge was creating the site in pure HTML/CSS/JavaScript but I chose to do this because I really wanted to understand the inner workings of web development. 
                I'm pleased to say that the site is still used and maintained by Theta Tau Omicron Delta Chapter!`,

      techStack: "HTML/CSS/JavaScript/Bootstrap",
      height: 400,
      width: 700,
      isFeatured: true,
      delayAnimation: "0",
      portfolioLink: "https://thetatau.rutgers.edu",
      githubLink: "https://github.com/louishinohara/ThetaTauWebsite",
    },
    {
      tag: ["UI/UX", "Mobile Dev", "Database"],
      img: "assets/portfolio-masonry/myRutgersFlutterApp.png",
      title: "myRutgers Mobile App",
      description: `The myRutgers Flutter app is going to replace the current official Rutgers University app that is used by over 70,000 students, faculty ands staff across all 3 Rutgers campuses. 
                We redesigned the entire UI for this new app and created it from scratch. The app is projected to be released in Summer 2022!`,
      techStack: "Flutter/Firebase",
      height: 400,
      width: 400,
      isFeatured: true,
      delayAnimation: "100",
      portfolioLink: "",
      githubLink: null,
    },
    {
      tag: ["Other"],
      img: "assets/portfolio-masonry/imageBlur.gif",
      title: "Image Blur",
      description: `A MATLAB application that I created in my first coding class (MATLAB) which adjusts the blur on the image using an algorithm I created. 
                The can upload an image through a GUI and use a slider to adjust the blur level.`,
      techStack: "MATLAB",
      height: 400,
      width: 400,
      isFeatured: false,
      delayAnimation: "0",
      portfolioLink: null,
      githubLink: "https://github.com/louishinohara/ImageBlur",
    },
    {
      tag: ["Web Dev"],
      img: "assets/portfolio-masonry/portfolio-v1.png",
      title: "Portfolio Site v1",
      description: `The first version of my portfolio website which I created to showcase my education, projects and work history. I created this website in raw HTML/CSS/JavaScript around the same time I created the Theta Tau website. `,
      techStack: "HTML/CSS/JavaScript/Bootstrap",
      height: 400,
      width: 400,
      isFeatured: false,
      delayAnimation: "200",
      portfolioLink: "https://www.louishinohara.xyz/",
      githubLink: "https://github.com/louishinohara/portfolio",
    },
    {
      tag: ["Mobile Dev"],
      img: "assets/portfolio-masonry/calc.gif",
      title: "Calculator App",
      description: `Calculator app is the first mobile app I've ever created and performs basic mathematical functions. 
                I created this app while I was experimenting with mobile app development and wanted to learn how to develop iOS apps.`,
      techStack: "Swift/Xcode",
      height: 400,
      width: 400,
      isFeatured: false,
      delayAnimation: "0",
      portfolioLink: null,
      githubLink: "https://github.com/louishinohara/Calculator",
    },
    {
      tag: ["UI/UX", "Web Dev", "Database"],
      img: "assets/portfolio-masonry/myRutgersPortal.png",
      title: "myRutgers Web Portal",
      description: `This myRutgers web application is going to replace the current myRutgers University portal that is used by over 70,000 students, faculty ands staff across all 3 Rutgers campuses. 
            We redesigned the entire UI based on the myRutgers flutter app we created and it's responsive design works for both web and mobile browsers. This app is set to be released in Summer 2022!`,
      techStack: "React/Firebase",
      height: 400,
      width: 550,
      isFeatured: true,
      delayAnimation: "100",
      portfolioLink: "https://my.rutgers.edu",
      githubLink: null,
    },
    {
      tag: ["Artificial Intelligence"],
      img: "assets/portfolio-masonry/bwImageColorization.jpg",
      title: "BW Image Colorization",
      description:
        "Wrote a program based on the kMeans algorithm to analyze the colors on the left side of an image and fill in the colors on the right side that was converted into a black and white image.",
      techStack: "Python",
      height: 400,
      width: 700,
      isFeatured: false,
      delayAnimation: "100",
      portfolioLink: null,
      githubLink: "https://github.com/SurajSanyal/Project4-AI",
    },
    {
      tag: ["Mobile Dev"],
      img: "assets/portfolio-masonry/orderingApp.JPG",
      title: "Restaurant Ordering App",
      description:
        "An ordering system app to be used by a small coffee shop. I first wrote the back end in Java and then converted it into a mobile application using Android Studio",
      techStack: "Java/Android Studio",
      height: 400,
      width: 400,
      isFeatured: false,
      delayAnimation: "200",
      portfolioLink: null,
      githubLink: "https://github.com/AtmosF34R/Project4",
    },
    {
      tag: ["Web Dev", "Database"],
      img: "assets/portfolio-masonry/auctionWebsite.png",
      title: "Auction Website",
      description: `A car auctioning website which mimics the functionalities of Ebay. This site allows users to place a car up for auction 
                and other users to bid on them. It also includes a feature to allow the user to set up auto bidding which places a 
                bid for the user if they are outbid to an upper threshold that they set themselves.
                This is a full stack application which uses JSP with Tomcat for the server and mySQL for the database.`,
      techStack: "Java/MySQL/TomCat",
      height: 400,
      width: 550,
      isFeatured: false,
      delayAnimation: "200",
      portfolioLink: null,
      githubLink: "https://github.com/louishinohara/auction-website",
    },
    {
      tag: ["Other"],
      img: "assets/portfolio-masonry/gameoflife.gif",
      title: "Game Of Life",
      description:
        "A simple application that mimics Conway's Game of Life which I wrote in my MATLAB class.",
      techStack: "MATLAB",
      height: 400,
      width: 400,
      isFeatured: false,
      delayAnimation: "300",
      portfolioLink: null,
      githubLink: "https://github.com/louishinohara/ImageBlur",
    },
    {
      tag: ["Artificial Intelligence"],
      img: "assets/portfolio-masonry/mazeOnFire.jpg",
      title: "Maze on Fire",
      description: `An application that finds the optimal path in a maze where sections of it are being blocked by 'fire' over time. 
                The challenge was to consider future outcomes of where the fire will be and still find the most optimal path.`,
      techStack: "Python",
      height: 400,
      width: 700,
      isFeatured: false,
      delayAnimation: "300",
      portfolioLink: null,
      githubLink: "https://github.com/louishinohara/Project1-AI",
    },
    {
      tag: ["Other"],
      img: "assets/portfolio-masonry/zoom.jpg",
      title: "Got It",
      description: `Application which captures audio during a zoom call and creates a transcription in real time.`,
      techStack: "Python",
      height: 400,
      width: 700,
      isFeatured: false,
      delayAnimation: "300",
      portfolioLink: null,
      githubLink: "https://github.com/louishinohara/GotIt",
    },
  ],
};

export const jp = {
  lang: "jp",
  about: {
    Language: "jp",
    Name: "篠原 潮",
    Title: "ソフトウェア エンジニア",
    description1: `私は3年以上の経験を持つフリーランサーのフロントエンド開発者です。
        私はサンフランシスコから来ました。私は世界中の素晴らしい人々のためにウェブ要素をコーディングして作成しています。
        私は新しい人と仕事をするのが好きです。新しい人々新しい経験。`,
    description2: `私は3年以上の経験を持つフリーランサーのフロントエンド開発者です。
        私はサンフランシスコから来ました。私は世界中の素晴らしい人々のためにウェブ要素をコーディングして作成しています。
        私は新しい人と仕事をするのが好きです。新しい人々新しい経験。`,
    FullName: "篠原 潮",
    Birthday: "1996年 10月11日",
    Address: "Jersey City, NJ",
    Phone: "+1(201)-398-8872",
    Email: "ushiolshinohara@gmail.com",
    Education: "Rutgers, The State University of NJ",
    Major: "B.S Electrical/Computer Engineering & Computer Science",
    Affiliations: "Theta Tau Omicron Delta Chapter, Tau Beta Pi Beta Chapter",
  },
  about_const: {
    Biography: "私について",
    Name: "名前: ",
    Birthday: "誕生日: ",
    Major: "選考科目: ",
    Education: "大学: ",
    Address: "住所: ",
    Phone: "電話番号: ",
    Email: "E-メール: ",
    WhatIDo: "私は何をしますか？",
    Affiliations: "所属: ",
    Education_Header: "教育",
  },
  contact: {
    Header: "連絡しましょう！",
    Info: "興味深いプロジェクトが発生した場合は、いつでも仕事に利用できます。自由に連絡してください。",
  },
  contactForm: {
    NameHeader: "名前",
    NameRequired: "名前が必要です",
    EmailHeader: "E-メールアドレス",
    EmailRequired: "E-メールアドレスが必要です",
    EmailFormatError: "入力した値がE-メール形式と一致しません",
    SubjectHeader: "主題",
    SubjectRequired: "主題が必要です",
    MessageHeader: "ここにメッセージを残してください",
    MessageRequired: "メッセージが必要です",
    SendMessage: "送信",
  },
  education_header: "Education",
  education: [
    {
      passingYear: "2018-2021",
      degreeTitle:
        "B.S. Electrical and Computer Engineering & Computer Science",
      instituteName: "Rutgers, The State University",
    },
    {
      passingYear: "2011-2015",
      degreeTitle: "High School Diploma",
      instituteName: "Paramus High School",
    },
    {
      passingYear: "2003-2014",
      degreeTitle: "Diploma",
      instituteName: "Japanese Weekend School of NJ",
    },
  ],
  experience_header: "経験",
  fourofour: {
    PageNotFound: "404 - ページが見つかりません",
    Message: "おっと、リクエストしたページが見つかりませんでした。",
    BackToHome: "ホームページに戻ります",
  },
  resume: [
    {
      companyName: "Microsoft",
      jobPosition: `Software Engineer 2`,
      jobDuration: `2022年6月-現在`,
      skillsUsed: [
        "React",
        "Python",
        "Java",
        "Docker",
        "Kubernetes",
        "Big Data",
        "Map Reduce",
        "Spark",
      ],
      jobDescription: `AdTechのビッグデータフルスタックエンジニアとして、私は自己サービス型のビッグデータプラットフォームを管理しています。このプラットフォームは、データエンジニアや科学者がユーザーインターフェースを通じてさまざまなジョブ（Java、Python、MapReduce、Spark、Bashなど）を提出できるようにサポートしています。これらのジョブは、Hadoopインフラストラクチャ上で動作するバックエンドスケジューラアプリケーションによってスケジュールされ、実行されます。私たちのシステムには、ジョブスケジュールを監視し、タイムリーな実行を確保するためのアラートメカニズムが含まれています。また、毎時または毎日実行される約4,000のジョブを管理する責任も私にあります。さらに、私の役割には、SQLデータベースやDocker、Kubernetes、Jenkinsなどのさまざまなツールを使用してCI/CDパイプラインを設定する作業も含まれています。私は頻繁にHiveやPrestoのようなデータ処理ツールを使用しています。また、800以上のグローバル顧客にイベントレベルの広告データを提供する製品であるLLDの管理も重要な責任です。この役割では、新しいジョブの作成や最適化を含むデータエンジニアリングタスクのために、MapReduceやSparkのジョブを広範囲にわたって使用する必要があります。私のフルスタックの責任は、ビッグデータツールや技術への広範な露出を提供してくれます。さらに、私の仕事にはAzure、AWS、Google Cloud Storageなどのクラウドプロバイダーとの連携も含まれており、クラウドベースのビッグデータソリューションにおける専門知識をさらに広げています。`,
      delayAnimation: "",
    },
    {
      companyName: "Rutgers University",
      jobPosition: `Web App Developer`,
      jobDuration: `2020年9月-現在`,
      skillsUsed: [`React`, "Redux", "Firebase"],
      jobDescription: `
              私は現在、80,000人以上の学生が使用できるように大学ポータルの再設計と実装に取り​​組んでいます
              3つのラトガースキャンパスすべての教職員。 Optum-United Health Groupでのインターンシップを終えた後、
              私はモバイルアプリチームから選ばれ、モバイルデザインを採用し、レスポンシブWebアプリケーションをReactからゼロから実装しました。私たちのチームは過去に大きく成長しました
              数年で多くの課題に直面しましたが、Webアプリケーションのベータ版がリリースされたことを誇りに思います
              大学にアプリを公開するまでに数か月かかります。`,
      delayAnimation: "100",
    },
    {
      companyName: "AT&T",
      jobPosition: `Software Engineer`,
      jobDuration: `2021年7月-2022年6月`,
      skillsUsed: ["Python", "Java"],
      jobDescription: `TDPプログラムの一環としての最初のローテーションでは、会社の広告技術部門であるXandrの一部です。
            私は、Scala、HDFS、Airflow、Spinnaker、データパイピングなど、多くの言語やテクノロジーに触れてきました。
            最初の主要なプロジェクトでは、PythonでSlackボットを作成して、会社のデータベースからユーザーの情報をすばやく収集し、アプリケーションをDockerイメージとしてKubernetesにデプロイしました。
            現在、Flaskの古いアプリケーションをReactアプリがデータを取得するために通信するマイクロサービスに変換することで、フラスコアプリをReactアプリに変換しています。
            また、Kubernetesを使用して構内アプリケーションをクラウドに移行することに関連する作業も行っています。`,
      delayAnimation: "",
    },

    {
      companyName: "Rutgers University",
      jobPosition: `Mobile App Developer`,
      jobDuration: `2019年12月-2020年9月`,
      skillsUsed: [`Flutter`, "Firebase", "AdobeXD"],
      jobDescription: `モバイルアプリの開発者として、私は3つのラトガースキャンパスすべてで使用される公式のラトガーモバイルアプリの新しい再設計バージョンに取り組みました。私たちはとして始めました
            3人の小さなチームで、新しいアプリのリポジトリを最初から開始しました。私たちのチームの規模は非常に大きくなり、現在私たちは
            アプリを大学にリリースしてから数か月後のベータ段階です。私はもうモバイルアプリで直接作業をしていませんが、それでも
            チームは、モバイルアプリの設計がWebアプリケーションと並行していることを確認します。`,
      delayAnimation: "100",
    },
    {
      companyName: "Knowt",
      jobPosition: `Mobile App Developer`,
      jobDuration: `2020年1月-2021年4月`,
      skillsUsed: [`React Native`],
      jobDescription: `
            Optumで仮想インターンとして働いていたときに、従業員の予測分析Webアプリツールを作成しました。
            Reactの社内システムの下で。このツールは、トップレベルの幹部がどの従業員が最も多いかを予測するために使用されました
            各従業員から収集したデータに基づいて上位に昇進した場合、統計的に成功する可能性があります。このインターンシップは私が多くのことを学んだ場所でした
            今では私の最強のスキルセットの1つであるReact！`,
      delayAnimation: "200",
    },
    {
      companyName: "Optum - UnitedHealth Group",
      jobPosition: `Full Stack Software Engineer`,
      jobDuration: `2020年1月-2020年8月`,
      skillsUsed: [`React`, "Redux"],
      jobDescription: `
            インターンとして、従業員の予測分析Webアプリツールを作成しました
            内部の企業システムの下で。このツールは、トップレベルの幹部がどの従業員が最も多いかを予測するために使用されました
            各従業員に関するデータに基づいて上位に昇格した場合、統計的に成功する可能性があります。`,
      delayAnimation: "300",
    },
  ],
  services: [
    {
      icon: "gear",
      title: "Software Engineering",
      descriptions: `APIとも通信し、DockerとKubernetesを使用してそれらのアプリケーションを
            サーバーにデプロイしたフロントエンドと統合するように設計されたアプリケーションアーキテクチャとデータベース`,
      delayAnimation: "",
    },
    {
      icon: "data",
      title: "Big Data",
      descriptions: `私は、広告技術自己サービスデータプラットフォームのためのビッグデータを専門としています。Hadoop、Hive、Presto、Trino、Spark、MapReduceなど、さまざまなビッグデータツールに精通しています。`,
      delayAnimation: "200",
    },
    {
      icon: "computer",
      title: "Web Application Development",
      descriptions: `Reactでの豊富な経験と、Webサイトから社内ツールや大学のアプリケーションに至るまで、さまざまなアプリケーションを作成してきました。`,
      delayAnimation: "400",
    },
  ],
  slider: {
    header: `こんにちは、私の名前は`,
    name: "篠原 潮",
    description: `私はMicrosoftのソフトウェアエンジニアで、広告技術のデータプラットフォームにおけるビッグデータを専門としています`,
    btnText: "履歴書をダウンロード",
    btnText2: "プロジェクト",
  },
  tab_list: [
    "All",
    "UI/UX",
    "Web Dev",
    "Mobile Dev",
    "Database",
    "Artificial Intelligence",
    "Other",
  ],
  portfolio_header: "私のポートフォリオ",
  portfolio_content: [
    {
      tag: ["UI/UX", "Web Dev"],
      img: "assets/portfolio-masonry/OT-site.gif",
      title: "thetatau.rutgers.edu",
      description: `ラトガースの Theta Tau のウェブサイトは、私が取り組んだ最初の主要なプロジェクトでした。友愛の新しいメンバーになった後、私はウェブマスターに選出され、
                夏の間、テンプレートなしでゼロから新鮮なデザインで新しいサイトを作成することは私自身にありました。私の最大の課題は、純粋なHTML / CSS / JavaScriptでサイトを作成することでしたが、Web開発の内部動作を本当に理解したかったので、これを選択しました。
                このサイトは、シータタウオミクロンデルタチャプターによって現在も使用および維持されていることを嬉しく思います。`,

      techStack: "HTML/CSS/JavaScript/Bootstrap",
      height: 400,
      width: 700,
      isFeatured: true,
      delayAnimation: "0",
      portfolioLink: "https://thetatau.rutgers.edu",
      githubLink: "https://github.com/louishinohara/ThetaTauWebsite",
    },
    {
      tag: ["UI/UX", "Mobile Dev", "Database"],
      img: "assets/portfolio-masonry/myRutgersFlutterApp.png",
      title: "myRutgers Mobile App",
      description: `myRutgers Flutterアプリは、3つのRutgersキャンパスすべてで、70,000人を超える学生、教職員、およびスタッフが使用している現在の公式のRutgersUniversityアプリに代わるものです。
                この新しいアプリのUI全体を再設計し、ゼロから作成しました。アプリは2022年夏にリリースされる予定です！`,
      techStack: "Flutter/Firebase",
      height: 400,
      width: 400,
      isFeatured: true,
      delayAnimation: "100",
      portfolioLink: "",
      githubLink: null,
    },
    {
      tag: ["Other"],
      img: "assets/portfolio-masonry/imageBlur.gif",
      title: "Image Blur",
      description: `最初のコーディングクラス（MATLAB）で作成したMATLABアプリケーションで、作成したアルゴリズムを使用して画像のぼかしを調整します。
                は、GUIを介して画像をアップロードし、スライダーを使用してぼかしレベルを調整できます。`,
      techStack: "MATLAB",
      height: 400,
      width: 400,
      isFeatured: false,
      delayAnimation: "0",
      portfolioLink: null,
      githubLink: "https://github.com/louishinohara/ImageBlur",
    },
    {
      tag: ["Web Dev"],
      img: "assets/portfolio-masonry/portfolio-v1.png",
      title: "Portfolio Site v1",
      description: `教育、プロジェクト、職歴を紹介するために作成したポートフォリオWebサイトの最初のバージョン。 Theta Tau Webサイトを作成したのとほぼ同時に、このWebサイトを生のHTML / CSS / JavaScriptで作成しました。`,
      techStack: "HTML/CSS/JavaScript/Bootstrap",
      height: 400,
      width: 400,
      isFeatured: false,
      delayAnimation: "200",
      portfolioLink: "https://www.louishinohara.xyz/",
      githubLink: "https://github.com/louishinohara/portfolio",
    },
    {
      tag: ["Mobile Dev"],
      img: "assets/portfolio-masonry/calc.gif",
      title: "Calculator App",
      description: `電卓アプリは、私がこれまでに作成した最初のモバイルアプリであり、基本的な数学関数を実行します。
                このアプリは、モバイルアプリの開発を試しているときに作成し、iOSアプリの開発方法を学びたいと思っていました。`,
      techStack: "Swift/Xcode",
      height: 400,
      width: 400,
      isFeatured: false,
      delayAnimation: "0",
      portfolioLink: null,
      githubLink: "https://github.com/louishinohara/Calculator",
    },
    {
      tag: ["UI/UX", "Web Dev", "Database"],
      img: "assets/portfolio-masonry/myRutgersPortal.png",
      title: "myRutgers Web Portal",
      description: `このmyRutgersWebアプリケーションは、3つのRutgersキャンパスすべてで70,000人を超える学生、教職員、およびスタッフが使用している現在のmyRutgers大学ポータルに代わるものです。
            作成したmyRutgersフラッターアプリに基づいてUI全体を再設計しました。レスポンシブデザインは、ウェブブラウザとモバイルブラウザの両方で機能します。このアプリは2022年夏にリリースされる予定です！`,
      techStack: "React/Firebase",
      height: 400,
      width: 550,
      isFeatured: true,
      delayAnimation: "100",
      portfolioLink: "https://my.rutgers.edu",
      githubLink: null,
    },
    {
      tag: ["Artificial Intelligence"],
      img: "assets/portfolio-masonry/bwImageColorization.jpg",
      title: "BW Image Colorization",
      description:
        "kMeansアルゴリズムに基づいてプログラムを作成し、画像の左側の色を分析し、白黒画像に変換された右側の色を塗りつぶしました。",
      techStack: "Python",
      height: 400,
      width: 700,
      isFeatured: false,
      delayAnimation: "100",
      portfolioLink: null,
      githubLink: "https://github.com/SurajSanyal/Project4-AI",
    },
    {
      tag: ["Mobile Dev"],
      img: "assets/portfolio-masonry/orderingApp.JPG",
      title: "Restaurant Ordering App",
      description:
        "小さな喫茶店で使用する注文システムアプリ。最初にバックエンドをJavaで作成し、次にAndroidStudioを使用してモバイルアプリケーションに変換しました。",
      techStack: "Java/Android Studio",
      height: 400,
      width: 400,
      isFeatured: false,
      delayAnimation: "200",
      portfolioLink: null,
      githubLink: "https://github.com/AtmosF34R/Project4",
    },
    {
      tag: ["Web Dev", "Database"],
      img: "assets/portfolio-masonry/auctionWebsite.png",
      title: "Auction Website",
      description: `Ebayの機能を模倣した自動車オークションのウェブサイト。このサイトでは、ユーザーが車をオークションにかけることができます
                および他のユーザーがそれらに入札します。また、ユーザーが自動入札を設定できる機能も含まれています。
                ユーザーが自分で設定した上限しきい値を上回っている場合は、ユーザーに入札します。
                これは、サーバーにTomcat、データベースにmySQLを使用するJSPを使用するフルスタックアプリケーションです。`,
      techStack: "Java/MySQL/TomCat",
      height: 400,
      width: 550,
      isFeatured: false,
      delayAnimation: "200",
      portfolioLink: null,
      githubLink: "https://github.com/louishinohara/auction-website",
    },
    {
      tag: ["Other"],
      img: "assets/portfolio-masonry/gameoflife.gif",
      title: "Game Of Life",
      description:
        "MATLABクラスで作成したConwayのGameofLifeを模倣した単純なアプリケーション。",
      techStack: "MATLAB",
      height: 400,
      width: 400,
      isFeatured: false,
      delayAnimation: "300",
      portfolioLink: null,
      githubLink: "https://github.com/louishinohara/ImageBlur",
    },
    {
      tag: ["Artificial Intelligence"],
      img: "assets/portfolio-masonry/mazeOnFire.jpg",
      title: "Maze on Fire",
      description: `迷路の一部が時間の経過とともに「火」によってブロックされている迷路の中で最適なパスを見つけるアプリケーション。
                課題は、火災が発生する場所の将来の結果を検討し、それでも最適な経路を見つけることでした。`,
      techStack: "Python",
      height: 400,
      width: 700,
      isFeatured: false,
      delayAnimation: "300",
      portfolioLink: null,
      githubLink: "https://github.com/louishinohara/Project1-AI",
    },
    {
      tag: ["Other"],
      img: "assets/portfolio-masonry/zoom.jpg",
      title: "Got It",
      description: `ズーム通話中に音声をキャプチャし、リアルタイムで文字起こしを作成するアプリケーション。`,
      techStack: "Python",
      height: 400,
      width: 700,
      isFeatured: false,
      delayAnimation: "300",
      portfolioLink: null,
      githubLink: "https://github.com/louishinohara/GotIt",
    },
  ],
};

export const contactInfo = {
  phone: "+1 (201)-398-8872",
  email: "ushiolshinohara@gmail.com",
};
