import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Masonry from "react-masonry-css";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import {
  FaStar,
  FaExternalLinkAlt,
  FaInfoCircle,
  FaGithubSquare,
} from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import SelectLang from "../../hooks/selectLang";
import { selectTheme, selectThemeColor } from "../../redux/portfolioSlice";

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  1000: 2,
  500: 1,
};

const PortfolioAnimation = () => {
  const language = SelectLang();
  const tabList = language["tab_list"];
  const AllPortfolioContent = language["portfolio_content"];
  const isDarkMode = useSelector(selectTheme);
  const themeColor = useSelector(selectThemeColor);
  const [theme, setTheme] = React.useState(themeColor);

  // Used to select random color from list.
  // Shows that changing value in redux will update css vals too
  React.useEffect(() => {
    setTheme(themeColor);
  }, [themeColor]);

  const createTabList = () => {
    return (
      <TabList
        className="filter d-flex flex-wrap justify-content-start"
        style={theme}
      >
        {tabList.map((val, i) => (
          <Tab key={i}>{val}</Tab>
        ))}
      </TabList>
    );
  };

  const createTabPanel = (tag) => {
    const getRandomFlipDirection = () => {
      const directions = ["vertical", "horizontal"];
      const randomInt = Math.floor(Math.random() * 2);
      return directions[randomInt];
    };

    return (
      <TabPanel>
        <div className="portfolio-content">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {AllPortfolioContent.map(
              (val, i) =>
                (tag == "All" || val.tag.includes(tag)) && (
                  <div
                    className="portfolio-box-01"
                    style={{
                      backgroundColor: isDarkMode ? "#0e0e17" : "#e7e7e7",
                    }}
                    key={i}
                    data-aos="fade-right"
                    data-aos-duration="1200"
                    data-aos-delay={val.delayAnimation}
                  >
                    <div className="portfolio-img">
                      <Flippy
                        flipOnHover={true} // default false
                        flipDirection={getRandomFlipDirection()} // horizontal or vertical
                      >
                        <FrontSide style={{ padding: 0 }}>
                          <div className="img-container">
                            <img src={val.img} />
                            {val.isFeatured && (
                              <div className="feature-icon">
                                <FaStar color="#f7d82c" size="1.8rem" />
                              </div>
                            )}
                          </div>
                        </FrontSide>
                        <BackSide
                          style={{
                            backgroundColor: themeColor["--themeColor"],
                          }}
                        >
                          <Grid
                            container
                            className="portfolio-info"
                            direction="column"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <h5 style={{ fontSize: "1.4rem", color: '#f0f0f0' }}>
                                {val.title}
                              </h5>
                              <span style={{ fontSize: "1rem",color: '#e7e7e7' }}>
                                <i>{val.techStack}</i>{" "}
                              </span>
                              <p> {"\n"} </p>
                              <p style={{ fontSize: "1rem", color: '#e7e7e7' }}>
                                {" "}
                                {val.description}{" "}
                              </p>
                            </Grid>

                            <Grid item>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                              >
                                <Grid item>
                                  {val.portfolioLink != null && (
                                    <IconButton
                                      className="icon-button"
                                      aria-label="delete"
                                      onClick={() =>
                                        window.open(val.portfolioLink)
                                      }
                                    >
                                      <FaExternalLinkAlt
                                        className="external-link-icons"
                                        size="1.4rem"
                                      />
                                    </IconButton>
                                  )}
                                </Grid>
                                <Grid item>
                                  {val.githubLink != null && (
                                    <IconButton
                                      className="icon-button"
                                      aria-label="delete"
                                      onClick={() =>
                                        window.open(val.githubLink)
                                      }
                                    >
                                      <FaGithubSquare
                                        className="external-link-icons"
                                        size="1.5rem"
                                      />
                                    </IconButton>
                                  )}
                                </Grid>
                                {/* <Grid item>
                                  <IconButton
                                    className="icon-button"
                                    aria-label="delete"
                                    onClick={() =>
                                      window.open(val.portfolioLink)
                                    }
                                  >
                                    <FaInfoCircle
                                      className="external-link-icons"
                                      size="1.4rem"
                                    />
                                  </IconButton>
                                </Grid> */}
                              </Grid>
                            </Grid>
                          </Grid>
                        </BackSide>
                      </Flippy>
                      {/* End gallery link */}
                    </div>
                  </div>
                )
            )}
          </Masonry>
        </div>
        {/* End list wrapper */}
      </TabPanel>
    );
  };
  return (
    <div className="portfolio-filter-01">
      <Tabs>
        {createTabList()}
        {/* End tablist */}

        {createTabPanel("All")}
        {createTabPanel("UI/UX")}
        {createTabPanel("Web Dev")}
        {createTabPanel("Mobile Dev")}
        {createTabPanel("Database")}
        {createTabPanel("Artificial Intelligence")}
        {createTabPanel("Other")}
        {/* End tabpanel */}
      </Tabs>
    </div>
  );
};

export default PortfolioAnimation;
