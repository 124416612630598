import React from "react";
import { Link } from "react-router-dom";
import useDocumentTitle from "../hooks/useDocumentTitle";
import selectLang from '../hooks/selectLang'

const NotFound = () => {
  useDocumentTitle("Page Not Found");
  const language = selectLang()
  const lang = language['fourofour']

  return (
    <>
      <section className="error-page-wrapper">
        <div className="container">
          <div className="row justify-content-center full-screen align-items-center">
            <div className="col-lg-8 text-center">
              <div className="inner">
                <h1 className="display-3 m-15px-b" style={{color: '#cc0033'}}>
                  {lang.PageNotFound}
                </h1>
                <p className="h4">
                {lang.Message}

                </p>
                <div className="btn-bar mt-4">
                  <Link className="px-btn px-btn-white" to="/">
                  {lang.BackToHome}

                  </Link>
                  {/* End purchase_button */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Error Page Wrapper */}

    </>
  );
};

export default NotFound;
