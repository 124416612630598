import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaGithub,
} from "react-icons/fa";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import SelectLang from '../../hooks/selectLang'
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../redux/portfolioSlice";

const SocialShare = [
  { Social: <FaInstagram />, link: "https://www.instagram.com/louishinohara/" },
  { Social: <FaFacebookF />, link: "https://www.facebook.com/louishinohara" },
  { Social: <FaTwitter />, link: "https://twitter.com/louishinohara" },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/louishinohara/",
  },
  { Social: <FaGithub />, link: "https://github.com/louishinohara" },
];

const ContactInfo = () => {
  const language = SelectLang()
  const lang = language['contact']
  const themeColor = useSelector(selectThemeColor)
  return (
    <>
      <div className="contact-info">
        <h4>{lang.Header}</h4>
        <p style={{marginBottom: 8}}>
        {lang.Info}
        </p>
        <div style={{marginBottom: 8}}>
          {SocialShare.map((val, i) => (
            <a
              className="contact-social-icons"
              style={{marginRight: 10,  fontSize: 20}}
              key={i}
              href={`${val.link}`}
              rel="noreferrer"
              target="_blank"
            >
              {val.Social}
            </a>
          ))}
        </div>
        <ul>
          {/* End li */}

          <li className="media">
            <PhoneIphoneIcon style={{color: themeColor['--themeColor']}} />
            <span className="media-body">{"+1 \xa0 (201)-398-8872"}</span>
          </li>
          {/* End li */}

          <li className="media">
            <EmailIcon style={{color: themeColor['--themeColor']}} />
            <span className="media-body">ushiolshinohara@gmail.com</span>
          </li>
          {/* End li */}
        </ul>
      </div>
      {/* End .contact-info */}
    </>
  );
};

export default ContactInfo;
