import React from "react";
import { useSelector } from "react-redux";
import Social from "../Social";
import SelectLang from "../../hooks/selectLang";
import Services from "../service/Service";
import { selectThemeColor } from "../../redux/portfolioSlice";

const About = () => {
  const lang = SelectLang();
  const language = lang["about"];
  const languageConst = lang["about_const"];
  const themeColor = useSelector(selectThemeColor);
  return (
    <>
      <section
        id="about"
        className="section theme-light dark-bg"
        style={themeColor}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-md-6 col-lg-4"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="about-me">
                <div className="img">
                  <div className="img-in">
                    {/* Image should be 1000 x 1065  */}
                    <img src="assets/img/me.jpg" alt="about image" />
                  </div>
                  <Social />
                  {/* End social icon */}
                </div>
                {/* End img */}
                <div className="info">
                  <h3>{language.Name}</h3>
                  <p>{language.Title}</p>
                </div>
                {/* End info */}
              </div>
              {/* End about-me */}
            </div>
            {/* End col */}

            <div
              className="col-lg-7 ml-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="about-info">
                <div className="title">
                  <h3>{languageConst.Biography}</h3>
                </div>
                <div>
                  <p className="about-text">{language.description1}</p>
                </div>
              </div>
              <div className="education-info">
                <div className="title">
                  <h3>{languageConst.Education_Header}</h3>
                </div>
                <div className="info-list">
                  <div className="row">
                      <div>
                        <ul>
                          <li>
                            <label style={{fontSize: '1.2rem'}}>{languageConst.Education} </label>
                            <span style={{fontSize: '1.1rem'}}>{language.Education}</span>
                          </li>
                          <li>
                            <label style={{fontSize: '1.2rem'}}>{languageConst.Major} </label>
                            <span style={{fontSize: '1.1rem'}}>{language.Major}</span>
                          </li>
                          <li>
                            <label style={{fontSize: '1.2rem'}}>{languageConst.Affiliations} </label>
                            <span style={{fontSize: '1.1rem'}}>{language.Affiliations}</span>
                          </li>
                        </ul>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End col */}

            <div
              className="separated"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "img/border-dark.png"
                })`,
              }}
            ></div>

            <div className="title">
              {/* <h3>{languageConst.WhatIDo}</h3> */}
              <h3>{languageConst.WhatIDo}</h3>
            </div>
            <Services />

            <div
              className="separated"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "img/border-dark.png"
                })`,
              }}
            ></div>
            {/* End separated */}
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
