/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isDarkMode: true,
    isEnglish: true,
    settingsDrawerOpened: false,
    themeColor: { "--themeColor": '' },
    colorsList: [
      "#cc0033",
      "#d67b00",
      "#117d44",
      "#7386d5",
      "#b3a400",
      '#e42bb0',
      '#37a2b6',
    ],
}

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    changeTheme: (state) => ({
      ...state,
      isDarkMode: !state.isDarkMode,
    }),
    changeLanguage: (state) => ({
      ...state,
      isEnglish: !state.isEnglish,
    }),
    handleSettingsDrawer: (state, action) => ({
      ...state,
      settingsDrawerOpened: action.payload,
    }),
    setThemeColor: (state, action) => ({
      ...state,
      themeColor: { "--themeColor": action.payload }
    }),
  },
  extraReducers: {
  },
})

export const selectTheme = (state) => state.portfolio.isDarkMode
export const selectLanguage = (state) => state.portfolio.isEnglish
export const selectSettingsDrawerOpened = (state) => state.portfolio.settingsDrawerOpened
export const selectThemeColor = (state) => state.portfolio.themeColor
export const selectColorList = (state) => state.portfolio.colorsList

export const {
    changeTheme,
    changeLanguage,
    handleSettingsDrawer,
    setThemeColor
} = portfolioSlice.actions

export default portfolioSlice.reducer
