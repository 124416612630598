import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import emailjs from "emailjs-com";
import { Grid, Button } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import SelectLang from "../../hooks/selectLang";
import { selectThemeColor } from "../../redux/portfolioSlice";
import ContactFormStyles from "./ContactFormStyles";

const ContactForm = () => {
  const language = SelectLang();
  const classes = ContactFormStyles();
  const themeColor = useSelector(selectThemeColor);
  const [showAlert, setShowAlert] = React.useState(false);

  const lang = language["contactForm"];
  React.useEffect(() => {
    emailjs.init("HgICyOYMK8TkhLq8l");
  }, []);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    const serviceID = "service_wg1zk8l";
    const templateId = "template_55p6c8q";
    const variables = {
      from_name: data.name,
      reply_to: data.email,
      message: data.comment,
    };
    emailjs
      .send(serviceID, templateId, variables)
      .then((res) => {
        setShowAlert(true);
        reset();
        console.log("Email successfully sent!");
      })
      .catch((err) =>
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        )
      );
  };

  return (
    <>
      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control theme-light"
                placeholder={lang.NameHeader}
                {...register("name", { required: true })}
              />
              {errors.name && errors.name.type === "required" && (
                <span className="invalid-feedback">{lang.NameRequired}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                type="email"
                className="form-control theme-light"
                placeholder={lang.EmailHeader}
                {...register(
                  "email",
                  {
                    required: lang.EmailRequired,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: lang.EmailFormatError,
                    },
                  },
                  { required: true }
                )}
              />
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control theme-light"
                placeholder={lang.SubjectHeader}
                {...register("subject", { required: true })}
              />
              {errors.subject && (
                <span className="invalid-feedback">{lang.SubjectRequired}</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="form-group mb-3">
              <textarea
                rows="4"
                className="form-control theme-light"
                placeholder={lang.MessageHeader}
                {...register("comment", { required: true })}
              ></textarea>
              {errors.comment && (
                <span className="invalid-feedback">{lang.MessageRequired}</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <Grid
            container
            // className="col-12"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={4} style={{ textAlign: "left" }}>
              <Button
                variant="contained"
                style={{ color: '#fff', backgroundColor: themeColor["--themeColor"] }}
                onClick={handleSubmit(onSubmit)}
              >
                {lang.SendMessage}
              </Button>
            </Grid>
            <Grid item xs={8}>
              <div
                className={showAlert ? classes.alertShown : classes.alertHidden}
                onTransitionEnd={() => setShowAlert(false)}
              >
                <Alert severity="success">Email sent successfully!</Alert>
              </div>
            </Grid>
          </Grid>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
};

export default ContactForm;
// style={{transition: 'all 250ms linear 2s'}}
