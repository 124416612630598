import * as React from "react";
import {
  selectColorList,
  selectThemeColor,
  setThemeColor,
  selectSettingsDrawerOpened,
} from "../redux/portfolioSlice";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { CirclePicker } from "react-color";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function ColorPickerSettingsTab({ isSettingTab = false }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const themeColor = useSelector(selectThemeColor);
  const colorsList = useSelector(selectColorList);
  const settingsDrawer = useSelector(selectSettingsDrawerOpened);

  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const toggleColorPicker = () => setShowColorPicker(!showColorPicker);

  React.useEffect(() => {
    const toggleVisibility = () => setShowColorPicker(false);
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  React.useEffect(() => {
    if (!settingsDrawer) {
      setShowColorPicker(false);
    }
  }, [settingsDrawer]);

  const handleChangeComplete = (color, event) => {
    const selectedColor = color.hex;
    dispatch(setThemeColor(selectedColor));
  };

  return (
    <>
      <div className={classes.swatch} onClick={() => toggleColorPicker()}>
        <CirclePicker
          className={classes.colorPicker}
          colors={colorsList}
          color={themeColor["--themeColor"]}
          onChangeComplete={handleChangeComplete}
          circleSize={20}
          width={'9rem'}
        />
      </div>
    </>
  );
}

const useStyles = makeStyles({
  color: {
    width: "36px",
    borderRadius: "2px",
  },
  colorPicker: {
    right: "1.3rem",
    marginTop: 6,
  },
  arrowDropDownIcon: {
    flex: 1,
    background: "#ebebeb",
  },
});
